.discountPriceWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &[data-variant='default'] {
    gap: var(--spacing-2);
  }
}

.originalPrice {
  display: flex;
  margin: 0;
  gap: var(--spacing-1);
  align-items: baseline;

  &[data-variant='default'] {
    gap: var(--spacing-2);
  }
}

.originalPriceText,
.strikedPrice {
  color: var(--color-tarmac-grey-500);
  font-size: 12px;
}

.originalPriceText,
.strikedPrice,
.discountPrice {
  margin: 0;
  @media (--mobile) {
    font-size: var(--spacing-3);
  }

  &[data-variant^='carTile'] {
    font-size: 11px;
  }
}

.strikedPrice {
  text-decoration: line-through;
}

.discountPrice,
.price {
  color: var(--color-error-700);
  margin: 0;
}

.price {
  &[data-variant='carTile'] {
    font-size: var(--spacing-4);
  }

  &[data-variant='carTileWithFinance'] {
    font-size: 14px;
  }
}
