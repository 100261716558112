.totalPrice {
  color: var(--color-neutral-700);
  margin: 0;

  &[data-variant='carTile'] {
    font-size: var(--spacing-4);
  }

  &[data-variant='carTileWithFinance'] {
    font-size: 14px;
    font-weight: 500;
  }
}
